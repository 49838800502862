

.articleBox{
    width: 260px;
    height: auto;
    padding: 10px 10px 0 10px;
    color : #515a6e;
    font-size: 12px;
    border: 1px solid #c2c2c2;
    float: left;
    margin: 10px;
}


.articleBox .f1{
    height: 40px;
    width: 240px;
    margin: 0 auto;
    line-height: 40px;
    border-bottom: 1px solid #c2c2c2;
    
}

.articleBox  .f2{
    height: 170px;
    width: 240px;
    margin: 0 auto;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 10px;

}

.articleBox .f3{
    height: 35px;
    width: 240px;
    margin: 0 auto;
}

.articleBox .arct-title{
    width: 100%;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    font-size: 12px;
}

.articleBox .pic{
    width: 100%;
    height: 140px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.articleBox .btna-1{
    width: 50%;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}

.articleBox .btna-1:first-child{
   border-right: 1px solid #c2c2c2;
}
.articleBox .btna-1:last-child{
    // border-left: 1px solid #c2c2c2;
}

// .articleBox .f3>.btn:nth-child(2){
//     border-left: 1px solid #c2c2c2;
//     border-right: 1px solid #c2c2c2;
//     text-align: center;
// }



















