.PicSearchList{
    width: 100%;
    height: 600px;
}




.PicSearchList .pic-search-list{
    width: 100%;
    height: 400px;
    margin-bottom: 10px;
    overflow: hidden;
}


.pic-search-tool{
    background-color: #ffffff;
    padding-right: 50px;
    padding-left: 10px;
    margin: 10px 0;
}


























