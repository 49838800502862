
.pub1 .publicTitle{
      width: 100%;
      height: 115px;
      border : 1px solid #e8eaec;
      background-color: #ffffff;
      margin-bottom: 24px;
      box-sizing: border-box;
  }
  
  
  .pub1  .pheader {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e8eaec;
      line-height: 50px;
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 24px;
  }
  
  
  .pub1  .search {
      margin-top: 15px;
      margin-left: 24px;
  }
  
  .pub1  .site-card-border-less-wrapper {
      padding: 30px;
      background: #ffffff;
      color: #515a6e;
  }
  
  .pub1  .tags{
      margin-top: 25px;
  }
  
  .pub1  .hfooter{
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    .btn{
      width: 32%;
      height: 30px;
      background-color: #f7f9fa;
      height: 100%;
      border : 1px solid #dddddd;
      text-align: center;
      border-radius: 3px;
      color : rgba(0,0,0,.45);
      cursor: pointer;
      padding: 5px 10px;
      font-size: 14px;
    }
  }
  
  
  .pub1  .pcon{
    width: 100%;
    min-height: 500px;
    height: auto;
  }
  