.newMat .ed{
    padding-top: 10px;
}


.newMat .editor-info{
    width: 500px;
    min-height: 500px;
}

.newMat .ed-imgcover-show{
    width: 100%;
    height: 120px;
    box-sizing: border-box;
    margin: 20px 0;
    padding: 0 20px;
    .ed-imagecover{
        width: 100%;
        height: 100%;
        background-color: #eeeeee;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain; 
    }
}

.newMat .ed .ed-btn{
    margin: 20px auto;
    font-weight: 400;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px 15px 6px;
    font-size: 12px;
    border-radius: 10px;
    transition: color .2s linear,background-color .2s linear,border .2s linear,box-shadow .2s linear;
    color: #515a6e;
    background-color: #fff;
    border-color: #dcdee2;
    width: 200px;

}

.newMat .ed-pub{
    padding : 10px;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(81, 90, 110);

}

.newMat .ed-pub .ed-pub-img{
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}



.newMat .ed-base-info{
    width: 350px;
    margin-left: 50px;
}



.newMat .ed-checkbox{
    margin-left: 100px;
    width: 350px;
}


.newMat .ed-control{
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0 , 0.5);
    z-index: 11111;
}

.newMat .ed-right{

}