html,body {
    color: #000000;
    margin: 0;
    height: 100%;
    font-family: "Myriad Set Pro","Helvetica Neue",Helvetica,Arial,Verdana,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #000;
}

a, label, button, input, select {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
    border: 0;
}

body {
    background-color: #ffffff;
}

html, body, div, dl, dt, dd, ol, ul, li, h1, h2, h3, h4, h5, h6, p, blockquote, pre, button, fieldset, form, input, legend, textarea, th, td {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

button {
    outline: 0;
}

img {
    border: 0;
}

button,input,optgroup,select,textarea {
    margin: 0;
    font: inherit;
    color: inherit;
    outline: none;
}


input{
    text-indent: 0;
    background: transparent;
    margin:  0;
    padding: 0;
    border: 0 none;
    resize:none; 
    outline:none;  /*清除选中效果的默认蓝色边框 */
    -webkit-appearance:none;  /*清除浏览器默认的样式 */
    line-height: normal;   
}

input, select {
    vertical-align:middle;
}

li {
    list-style: none;
}

a {
    color: #666;
}

.clear::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}

.lf{
    float: left;
}

.rf{
    float: right;
}

.dis {
    display: none;
}
