



.login{
    width: 100%;
    height: calc(100vh - 110px - 100px);
    background: url(../../assets/images/bg.jpg) no-repeat center center;
    // background-color: aqua;
}

.login-form{
    padding-top: 300px;
    width: 100%;
}

