.maincontent1{
    position: relative;
    top: 65px;
    width: calc(~'100% - 200px');
    height: calc(~'100% - 65px');
    margin-left: 200px;
    max-width: 100%;
    min-width: 1000px;
}

.maincontent2{
    position: relative;
    top: 65px;
    width: calc(~'100% - 80px');
    height: calc(~'100% - 65px');
    margin-left: 80px;
    max-width: 100%;
    min-width: 1000px;
}


.coninner{
    padding: 10px;
    min-height: 800px;
    min-width: 1100px;
    max-width: 100%;
    background-color: #eeeeee;
    overflow: initial;
}















