.mainHeader1 {
    width: calc(~'100% - 200px');
    height: 65px;
    position: fixed;
    left: 200px;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    z-index: 10;
}

.mainHeader2 {
    width: calc(~'100% - 80px');
    height: 65px;
    position: fixed;
    left: 80px;
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    z-index: 10;
}

.main-header-user {
    min-width: 120px;
    padding-top: 15px;
}

.main-header-user .username{
    font-size: 16px;
    color :#000000;
    margin-left: 20px;
}


.main-header-user .downlf{
    position: relative;
    top: 5px;
    margin: 0 15px;
}

.mainheader-item{
   text-align: center;
}



