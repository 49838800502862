.pub-article{


    
}

.pub-article .pub-art-main{
    padding-left: 10px;
    padding-top: 10px;
    width: 20%;
    margin-bottom: 10px;
}

.pub-article .pub-art-cont1{
    width: 800px;
    min-width: 800px;
    padding-left: 10px;
    height: auto;
}


.pub-article .pub-art-select{
    padding-top: 10px;
}


.pub-article .pub-art-cont1-1{
    padding-top: 10px;
    padding-left: 50px;
    box-sizing: border-box;
}


.pub-art-box{
    width: 420px;
    height: 110px;
    background-color: #eeeeee;
}

.pub-art-lf{
    width: 80%;
    height: 100%;
    border : 1px solid #000000;
    box-sizing: border-box;
    padding: 10px;
    background-color: #ffffff;
}

.pub-art-rf{
    width: 20%;
    height: 100%;
    border : 1px solid #333333;
    display: flex;    
    justify-content: space-around;
    align-items:center;
}


.pub-art-digest{
    width: 220px;
    height: 100%;
    line-height: 1.35;
    overflow: hidden;
}

.pub-art-rfbg{
    width: 90px;
    height: 100%;
    background-color: #eeeeee;
    background-repeat: no-repeat;
    background-position: 0 0;
    // background-size: contain;
    background-size: 100% auto;
}

.pub-first-main{
    background-repeat: no-repeat;
    background-position: 0 0;
    // background-size: contain;
    background-size: 100% auto;
}