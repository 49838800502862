.tipHeader{
    width: 100%;
    height: 60px;
    background-color: #ffffff;
}


.tip-title{
    width: 180px;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 700;
    color : #000000;
    line-height: 60px;
}