.mTitle{
    width: 100%;
    min-width: 1000px;
    height: auto;
    border : 1px solid #e8eaec;
    background-color: #ffffff;
    margin-bottom: 24px;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 25px;
}

.line{
    width: 100%;
    // border-bottom : 1px solid #e8eaec;
    margin-bottom: 24px;
}


.card-container p {
    margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
    height: auto;
    margin-top: -20px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 0px;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
    [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
    [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #141414;
    background: #141414;
}
.ctab{
    background-color: #fff;
    width: 100%;
    min-height: 500px;
    height: auto;
    padding: 15px;
}


.pag{
    width: 100%;
    margin-top: 20px;
    margin: 0 auto;
}
