

.picArticleBox{
    width: 260px;
    height: auto;
    padding: 10px 10px 0 10px;
    color : #515a6e;
    font-size: 12px;
    border: 1px solid #c2c2c2;
    float: left;
    margin: 10px;
}


.picArticleBox .f1{
    height: 40px;
    width: 240px;
    margin: 0 auto;
    line-height: 40px;
    border-bottom: 1px solid #c2c2c2;
    
}

.picArticleBox  .f2{
    height: 170px;
    width: 240px;
    margin: 0 auto;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 10px;

}

.picArticleBox .f3{
    height: 35px;
    width: 240px;
    margin: 0 auto;
}

.picArticleBox .pic-title{
    width: 50%;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    font-size: 12px;
}

.picArticleBox .pic-update-time{
    width: 50%;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    font-size: 12px;
    text-align: center;
}

.picArticleBox .pic-m-image{
    width: 100%;
    height: 140px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.picArticleBox .pic-m-image img{
    max-width: 100%;
    width: 100%;
    max-height: 100%;
}

.picArticleBox .btn-pic{
    width: 50%;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}

.picArticleBox .f3>.btn-pic:nth-child(2){
    border-left: 1px solid #c2c2c2;
    text-align: center;
}



















