

.aheader{
    margin: 0 auto;
    width: 100%;
    height: 110px;
    background-color: #eeeeee;   

}

.logo{
    width: 50px;
    height: 50px;
    margin-top: 30px;
}

.title{
    width: 100%;
    text-align: right;
    margin-top: 30px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    letter-spacing: 1px;
}












