
.pic-one{
    margin: 20px 0;
    width: 300px;
}


.pic-one-label{
    width: 300px;
    font-size: 14px;
    color: #000000;
}

